<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col class="ml-2" lg="3" sm="3">
        <v-autocomplete
          :items="venueServices"
          v-model="venueServiceIds"
          item-value="venue_service_id"
          item-text="name"
          label="Service"
          outlined
          multiple
          ref="venueService"
          @change="getSpaces"
          background-color="#fff"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggleSelect()">
              <v-list-item-action>
                <v-icon
                  :color="venueServiceIds.length > 0 ? 'teal darken-4' : ''"
                  >{{ getServiceIcon() }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template
            v-if="venueServiceIds.length == venueServices.length"
            v-slot:selection="{ index }"
          >
            <span v-if="index == 0">All Services</span>
          </template>
          <template v-else v-slot:selection="{ item, index }">
            <span v-if="index == 0">{{ item.name }}</span>
            <span v-if="index == 1">, {{ item.name }}</span>
            <span v-if="index == 2">, ...</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col lg="3" sm="3" style="float: left">
        <v-btn-toggle
          borderless
          class="button_navigation"
          v-model="spaceStatus"
          mandatory
          @change="getSpaces"
        >
          <v-btn value="1">Active</v-btn>

          <v-btn value="2">InActive</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-lg-right">
        <v-btn
          v-if="checkWritePermission($modules.space.management.slug)"
          class="teal-color"
          @click="addNewSpace"
          dark
          large
        >
          Add Space
          <v-icon right dark>mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="space in allSpaces" :key="space.id" lg="4">
        <space-widget
          v-bind="space"
          @viewMore="viewMoreData"
          @remove="deleteSpaceConfirm"
          @edit="editSpace"
        />
      </v-col>
    </v-row>
    <h3 class="text-center mt-12" v-if="allSpaces.length == 0">
      No {{ spaceStatus == 1 ? "active" : "inactive" }} space present
    </h3>

    <v-dialog
      v-model="addSpaceDialog"
      width="65%"
      scrollable
      @input="cancelSpace"
    >
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title class="headline">
            <span>{{ editFlag ? "Edit" : "Add" }} Space</span>
          </v-card-title>
          <v-card-text class="pt-5">
            <v-container class="space_bg">
              <v-row no-gutters justify="center">
                <v-col md="6">
                  <image-upload
                    @upload="
                      (data) => {
                        space.image = data;
                      }
                    "
                    :height="200"
                    :image_path="space.image_path"
                    @remove="
                      () => {
                        space.image = null;
                      }
                    "
                    defaultImage="stage"
                  ></image-upload>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-switch
                    :false-value="0"
                    :true-value="1"
                    :label="(space.is_enabled_seat_map? 'Disable': 'Enable') + 'Seat Map'"
                    required
                    v-model="space.is_enabled_seat_map"
                    solo
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    v-model="space.name"
                    label="Space Name*"
                    :rules="[(v) => !!v || 'Name required']"
                    required
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
                 <v-col cols="12" sm="12" md="3">
                  <v-select
                    v-model="space.facility_type_id"
                    label="Type*"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    item-text="name"
                    item-value="id"
                    :items="spaceTypes"
                    :rules="[(v) => !!v || 'Type required']"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="space.facility_services"
                    label="Service*"
                    :items="venueServices"
                    item-text="name"
                    item-value="venue_service_id"
                    multiple
                    outlined
                    background-color="#fff"
                    validate-on-blur
                    :rules="[(v) => v.length > 0 || 'Venue service required']"
                  >
                    <template
                      v-if="
                        space.facility_services.length == venueServices.length
                      "
                      v-slot:selection="{ index }"
                    >
                      <span v-if="index == 0">All Services</span>
                    </template>
                    <template v-else v-slot:selection="{ item, index }">
                      <span v-if="index == 0">{{ item.name }}</span>
                      <span v-if="index == 1">, ...</span>
                    </template>

                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggle('venueServices', 'facility_services')"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              space.facility_services.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                            >{{
                              getIcon("venueServices", "facility_services")
                            }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field
                    v-model="space.capacity"
                    label="Capacity*"
                    outlined
                    background-color="#fff"
                    :rules="[
                      (v) => !!v || 'Capacity is required',
                      (v) => !isNaN(v) || 'Capacity must be Number',
                    ]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="space.description"
                        name="input-7-1"
                        label="Description"
                        value
                        rows="7"
                        outlined
                        background-color="#fff"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        :items="weekdays"
                        label="Opening Days*"
                        multiple
                        v-model="space.open_days"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        item-value="bit_value"
                        item-text="name"
                        validate-on-blur
                        :rules="[(v) => v.length > 0 || 'Opening Days required']"
                      >
                        <template v-slot:selection="{ item, index }">
                          <span v-if="index < 1" class="pr-1"
                            >{{ item.name }},</span
                          >
                          <span v-if="index == 1" class="pr-1">{{
                            item.name
                          }}</span>
                          <span v-if="index == 2">, ...</span>
                        </template>

                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @click="toggle('weekdays', 'open_days')"
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  space.open_days.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                                >{{ getIcon("weekdays", "open_days") }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>All</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="space.opening_time"
                        :items="time.slice(0, 24)"
                        label="Opening Time*"
                        item-text="text"
                        item-value="value"
                        :rules="[(v) => !!v || 'Opening time Required']"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="space.closing_time"
                        :items="time.slice(1)"
                        item-text="text"
                        item-value="value"
                        label="Closing Time*"
                        :rules="[(v) => !!v || 'Closing time Required']"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div class="titles">Contact Details</div>
              <v-row no-gutters class>
                <v-col cols="12" sm="6" md="6" class="pr-2">
                  <v-text-field
                    v-model="space.contact_name"
                    label="Manager Name"
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="6" md="6" class="pl-2">
                  <mobile-field
                    v-model="space.contact_mobile"
                    label="Mobile"
                    :required="false"
                  ></mobile-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <div class="titles">Custom Field</div>
              </v-row>

              <v-card
                class="pa-2"
                outlined
                tile
                rounded
                v-for="(custom_field, k) in space.custom_fields"
                :key="k"
              >
                <v-row class="pb-2">
                  <v-col md="6" class="pr-0">
                    <v-text-field
                      label="Key"
                      required
                      v-model="custom_field.key"
                      outlined
                      background-color="#fff"
                    ></v-text-field>
                  </v-col>

                  <v-col md="5">
                    <v-text-field
                      label="Value"
                      required
                      v-model="custom_field.value"
                      outlined
                      background-color="#fff"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="1"
                    v-if="checkDeletePermission($modules.space.management.slug)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="del_btn"
                          color="red"
                          @click="deleteCustomKey(k)"
                          x-small
                          dark
                          fab
                        >
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      Delete
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>

              <div class="add_btn">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="teal-color"
                      fab
                      x-small
                      dark
                      @click="addNewCustomKey"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add Custom Field
                </v-tooltip>
              </div>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="cancelSpace"
              >Close</v-btn
            >
            <v-btn
              color=" darken-1"
              class="ma-2 white--text teal-color"
              @click="saveUpadateSpace"
              text
              >{{ this.space.id ? "Update" : "Save" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="view_more_dialog" scrollable persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Other Details</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="more_details.length > 0">
          <table class="history_table">
            <tr v-for="details in more_details" :key="details.id">
              <td>{{ details.key }}</td>
              <td>{{ details.value }}</td>
            </tr>
          </table>
        </v-card-text>
        <v-card-text v-else class="pa-8 text-center">
          <h3>No custom fields found!</h3>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 white--text blue-color"
            text
            @click="view_more_dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- View more_dialog end-->
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>
<script>
import SpaceWidget from "@/components/Facility/SpaceWidget";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import constants from "../../utils/constants";
export default {
  components: { SpaceWidget, ConfirmModel },
  data() {
    return {
      addSpaceDialog: false,
      space: { facility_services: [] },
      allSpaces: [],
      editImageHover: false,
      time: constants.TIMINGS,
      spaceTypes: [],
      view_more_dialog: false,
      more_details: "",
      editFlag: false,
      valid: true,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      spaceStatus: "1",
      venueServiceIds: [],
    };
  },
  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter((service) => service.name != "POS");
    },
  },
  mounted() {
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    this.getSpaces();
    this.getSpaceTypes();
  },
  methods: {
    getSpaces() {
      this.showLoader("Loading");
      let url = this.venueServiceIds
        .map((item, index) => `&venue_service_ids[${index}]=${item}`)
        .join(",");
      this.$http
        .get(`venues/facilities?type=space&status_id=${this.spaceStatus}${url}`)
        .then((response) => {
          if (response.status == 200) {
            this.allSpaces = response.data.data;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.space[field].length == this[type].length) {
          this.space[field] = [];
        } else {
          this.space[field] = this[type].map((item) => {
            if (type == "venueServices") {
              return item.venue_service_id;
            } else if (type == "weekdays") {
              return item.bit_value;
            }
          });
        }
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";
      if (this.space[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.space[field].length > 0 &&
        this.space[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    addNewSpace() {
      this.space = {
        facility_services: [],
        open_days: [],
        deleted_fields: [],
        custom_fields: [
          {
            key: "",
            value: "",
          },
        ],
      };
      this.addSpaceDialog = true;
      this.editFlag = false;
    },
    addNewCustomKey() {
      this.space.custom_fields.push({
        key: "",
        value: "",
      });
    },
    deleteCustomKey(index) {
      if (
        this.space.custom_fields[index].key == "" &&
        this.space.custom_fields[index].value == ""
      ) {
        this.space.custom_fields.splice(index, 1);
        if (!this.space.custom_fields.length) {
          this.space.custom_fields = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this custom field?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_custom_field",
        };
      }
    },
    viewMoreData(data) {
      this.more_details = data;
      this.view_more_dialog = true;
    },
    saveUpadateSpace() {
      event.preventDefault();
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      let formData = new FormData();
      formData.append("name", this.space.name);
      if (this.space.description) {
        formData.append("description", this.space.description);
      }
      formData.append("type", "space");
      formData.append("facility_category", this.space.facility_category);
      this.space.facility_services.forEach((serviceId, index) => {
        formData.append(`facility_services[${index}]`, serviceId);
      });
      this.space.open_days.forEach((day, index) => {
        formData.append(`open_days[${index}]`, day);
      });
      this.space.deleted_fields.forEach((id, index) => {
        formData.append(`deleted_fields[${index}]`, id);
      });
      this.space.custom_fields.forEach((field, index) => {
        if (field.key && field.value) {
          if (field.id != null) {
            formData.append(`custom_fields[${index}][id]`, field.id);
          }
          formData.append(`custom_fields[${index}][key]`, field.key);
          formData.append(`custom_fields[${index}][value]`, field.value);
        }
      });
      formData.append("capacity", this.space.capacity);
      formData.append("facility_type_id", this.space.facility_type_id);
      formData.append("opening_time", this.space.opening_time);
      formData.append("closing_time", this.space.closing_time);
      if (this.space.contact_name)
        formData.append("contact_name", this.space.contact_name);
      if (this.space.contact_mobile)
        formData.append("contact_mobile", this.space.contact_mobile);
      if (typeof this.space.image != "string" && this.space.image) {
        formData.append("image", this.space.image);
      }

      if (typeof this.space.is_enabled_seat_map !== "undefined" && this.space.is_enabled_seat_map !== "undefined") {
        formData.append("is_enabled_seat_map", this.space.is_enabled_seat_map);
      }
      this.$http({
        method: "post",
        data: formData,
        url: `venues/facilities${
          this.space.id != null ? "/" + this.space.id : ""
        }`,
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((res) => {
          if (res.status == 200) {
            this.showSuccess("Space Updated Successfully.");
            this.hideLoader();
            this.getSpaces();
            this.addSpaceDialog = false;
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    deleteSpaceConfirm(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${data.type} this space?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_space",
      };
    },
    removeSpace(id) {
      this.showLoader();
      this.$http
        .delete(`venues/facilities/${id}`)
        .then((res) => {
          if (res.status == 200) {
            this.showSuccess("Space deleted Successfully.");
            this.getSpaces();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    editSpace(id) {
      this.editFlag = true;
      this.space = { open_days: [], facility_services: [] };
      this.showLoader("Loading");
      this.$http
        .get(`venues/facilities/${id}`)
        .then((response) => {
          if (response.status == 200) {
            this.space = response.data.data;
            this.space.image_path = this.space.image;
            this.space.facility_services = this.space.facility_services.map(
              (item) => item.venue_service_id
            );
            if (this.space.custom_fields.length == 0) {
              this.space.custom_fields = [{}];
            }
            this.space.deleted_fields = [];
            this.addSpaceDialog = true;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    cancelSpace() {
      this.$refs.form.resetValidation();
      this.space.opening_days = "";
      this.addSpaceDialog = false;
    },
    confirmActions(data) {
      if (data.type == "delete_space") {
        this.removeSpace(data.id);
      } else if (data.type == "delete_custom_field") {
        if (this.space.custom_fields[data.id].id != null) {
          this.space.deleted_fields.push(this.space.custom_fields[data.id].id);
        }
        this.space.custom_fields.splice(data.id, 1);
        if (!this.space.custom_fields.length) {
          this.space.custom_fields = [{}];
        }
      }
      this.confirmModel.id = null;
    },
    getSpaceTypes() {
      this.$http
        .get("general-types/space-type")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.spaceTypes = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (this.venueServices.length == this.venueServiceIds.length) {
          this.venueServiceIds = [];
        } else {
          this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.getSpaces();
      });
    },
    getServiceIcon() {
      if (this.venueServiceIds.length == 0) return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.venueServiceIds.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
  },
};
</script>

<style scoped>
.add_ground_containers {
  width: -webkit-fill-available;
}
.v-application .title {
  text-shadow: 2px 2px 4px #000000;
}
.section_box {
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.del_btn {
  margin-top: 5px;
}
.space_bg {
  background: #edf9ff;
  border-radius: 5px;
}
.titles {
  text-align: left;
  color: #066a8c;
  font-size: 14px;
  padding: 3px 0 20px 12px;
  font-weight: bolder;
}
.add_btn {
  margin: 0 auto;
  text-align: center;
  padding: 9px;
}
.history_table {
  margin-top: 20px;
  font-size: 14px;
  width: 100%;
}

.history_table tr td {
  padding: 10px 6px;
  color: #000000;
  border: 1px solid #cccccc;
}
</style>
