<template>
  <v-card class="mt-2 ml-2 ground_card">
    <v-card-text class="text--primary ground_card">
      <div class="price_option_container">
        <v-row class="pr-2 pl-2">
          <v-col cols="12" md="5">
            <v-card flat tile class="d-flex">
              <view-image :image="image" defaultImage="stage"></view-image>
            </v-card>
          </v-col>

          <v-col cols="12" md="7">
            <v-row no-gutters class="pb-1">
              <v-col sm="5" class="left_col">Facility</v-col>
              <v-col sm="7" class="right_col">{{ name }}</v-col>
            </v-row>
            <v-row no-gutters class="pb-1">
              <v-col sm="5" class="left_col">Type</v-col>
              <v-col sm="7" class="right_col">{{ facility_type.name }}</v-col>
            </v-row>
            <v-row no-gutters class="pb-1">
              <v-col sm="5" class="left_col">Capacity</v-col>
              <v-col sm="7" class="right_col">{{ capacity }}</v-col>
            </v-row>
            <v-row no-gutters class="pb-1">
              <v-col sm="5" class="left_col">Time</v-col>
              <v-col sm="7" class="right_col"
                >{{ opening_time | timeFormat }} to
                {{ closing_time | timeFormat }}</v-col
              >
            </v-row>
            <v-row no-gutters class="pb-1">
              <v-col
                class="right_col overflow-y-auto grey--text text--lighten-2"
                style="min-height: 60px; max-height: 70px"
              >
                <div class="left_col">Opening Days</div>
                {{ getWeekdays(open_days) }}</v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="clear"></div>

      <div class="price_option_container">
        <div class="g_subheading">Contact Details:</div>
        <div class="pricing_option scroll">
          <div class="titledet fleft">
            <div class="caption1">
              <div class="contact">Name : {{ contact_name }}</div>
            </div>
          </div>
          <div class="titledet fleft">
            <div class="caption1">
              <div class="contact">Mobile: {{ contact_mobile }}</div>
            </div>
          </div>
        </div>
      </div>
      <v-btn
        class="mr-6 teal-color"
        block
        dark
        @click="view_more_dialogs(custom_fields)"
      >
        Other Details
      </v-btn>
    </v-card-text>

    <v-bottom-navigation
      v-if="
        checkWritePermission($modules.space.management.slug) ||
        checkDeletePermission($modules.space.management.slug)
      "
    >
      <v-spacer></v-spacer>
      <v-btn
        v-if="
          checkWritePermission($modules.space.management.slug) && status_id == 1
        "
        @click="edit"
        text
      >
        <span>Edit</span>
        <v-icon medium>mdi-pencil</v-icon>
      </v-btn>
       <v-spacer
        v-if="
          checkWritePermission($modules.space.management.slug) && status_id == 1
        "
      ></v-spacer>
      <v-btn
        v-if="checkReadPermission($modules.space.management.slug) && is_enabled_seat_map"
        @click="viewMaps"
        text
      >
        <span>Maps</span>
        <v-icon medium>mdi-map</v-icon>
      </v-btn>
      <v-spacer
        v-if="
          checkWritePermission($modules.space.management.slug) && status_id == 1
        "
      ></v-spacer>
      <v-btn
        v-if="checkDeletePermission($modules.space.management.slug)"
        @click="deleteStage"
        text
      >
        <span>{{ status_id == 1 ? "Delete" : "Reactivate" }}</span>
        <v-icon medium>mdi-{{ status_id == 2 ? "refresh" : "delete" }}</v-icon>
      </v-btn>
      <v-spacer
        v-if="checkDeletePermission($modules.space.management.slug)"
      ></v-spacer>
    </v-bottom-navigation>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    id: { type: Number },
    venue_id: { type: Number },
    name: { type: String, default: "" },
    facility_type: { type: Object, default: () => {} },
    description: { type: String, default: "" },
    image: { type: String, default: "" },
    opening_time: { type: String, default: "" },
    closing_time: { type: String, default: "" },
    contact_name: { type: String, default: "" },
    contact_mobile: { type: String, default: "" },
    capacity: { type: Number, default: null },
    open_days: { type: Array, default: () => [] },
    custom_fields: { type: Array, default: null },
    status_id: { type: Number, default: 1 },
    is_enabled_seat_map: { type: Number, default:0}
  },
  computed: {
    ...mapGetters({
      getWeekdays: "getWeekdaysFromBitvalue",
    }),
  },
  methods: {
    edit() {
      this.$emit("edit", this.id);
    },
    deleteStage() {
      this.$emit("remove", {
        id: this.id,
        type: this.status_id == 1 ? "delete" : "reactivate",
      });
    },
    view_more_dialogs(custom_fields) {
      this.$emit("viewMore", custom_fields);
    },
    viewMaps() {
       this.$router.push({
        name: "SpaceMaps",
        params: { data: btoa(this.id) },
      });
    }
  },
};
</script>

<style scoped>
.v-card__text {
  background-color: rgb(62, 76, 85);
  border-color: rgb(62, 76, 85);
}
.ground_det_table {
  color: rgb(235, 235, 235);
}
.ground_card {
  min-height: 261px;
}

.right_col {
  color: #ffffff;
}
.left_col {
  color: #b0ada8;
}
.contact,
.stage_box {
  font-size: 13px !important;
}

.pricing_option .caption1 {
  font-weight: 400;
  background: #2d3b46;
  padding: 5px 9px;
  margin: 1px 1px 10px 1px;
  border-radius: 6px;
  color: #c2b9a7;
  font-size: 15px;
}
.price_option_container {
  width: 100%;
}
.g_subheading {
  width: 100%;
  text-align: left;
  padding: 0 0 5px 7px;
  color: #fff;
}
.pricing_option .titledet {
  width: 50%;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.clear {
  clear: both;
}
.v-item-group.v-bottom-navigation .v-btn {
  font-size: 0.9rem;
}
</style>
